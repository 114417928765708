import React from 'react';

import styles from './ChallengeProgress.module.scss';

import cardinalEmptyRed from '../../assets/cardinal_empty_red.svg';
import cardinalEmpty from '../../assets/cardinal_empty.svg';
import cardinalFull from '../../assets/cardinal_full.svg';

function ChallengeProgress({progress}) {
	//TODO in the list of data that comes back, generate a class name for each

	const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

	const getProgressImageForDate = (progressItem) => {
		const date = new Date(progressItem.date + ' 00:00');
		if( progressItem.completed ){
			return <img src={cardinalFull} alt={"writing complete for "+days[date.getDay()]} />
		}
		const src = ( date.getDay() === 6 || date.getDay() === 0 ) ? cardinalEmpty : cardinalEmptyRed;
		return <img src={src} alt={"writing complete for "+days[date.getDay()]} />
	};


	return (
		<div className={styles.challengeProgress}>
			<ul>
				{progress.slice(0,7).map(progressItem => <li key={progressItem.date}>{getProgressImageForDate(progressItem)}</li>)}
			</ul>
			<ul>
				{progress.slice(7,14).map(progressItem => <li key={progressItem.date}>{getProgressImageForDate(progressItem)}</li>)}
			</ul>
			<ul>
				<li>M</li>
				<li>T</li>
				<li>W</li>
				<li>R</li>
				<li>F</li>
				<li>S</li>
				<li>S</li>
			</ul>
		</div>
	);
}

export default ChallengeProgress;