import {API_URL, SHIBBOLETH_URL, BASE_URL} from "./config/config";
import axios from "axios"

axios.interceptors.response.use(response => response, error => {
    if(error.response && error.response.status === 401) {
        window.location = SHIBBOLETH_URL;
    } else if (error.response && error.response.status === 500) {
        window.location = BASE_URL+"/error";
    }

    return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['Session-Key']=localStorage.getItem("adminChallengeSessionKey");
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default class Request {

    static updateEvent(event){
        return axios.put(API_URL+"events/"+event.id,event);
    }

    static createEvent(event){
        return axios.post(API_URL+"events",event);
    }

    static getAllAdmins(){
        return axios.get(API_URL+"admins");
    }

    static createAdmin(admin){
        return axios.post(API_URL+"admins",admin);
    }

    static removeAdmin(admin){
        return axios.delete(API_URL+"admins/"+admin.id);
    }

    static getEvent(eventId){
        return axios.get(API_URL+"events/"+eventId);
    }

    static getAllEvents() {
        return axios.get(API_URL+"events");
    }

    static getAllUsers() {
        return axios.get(API_URL+"users");
    }

    static getHistoryForUser(userID) {
        return axios.get(API_URL+"users/"+userID+'/history');
    }

    static getAnnouncement(){
        return axios.get(API_URL+"announcements");
    }

    static updateAnnouncement(announcement){
        return axios.put(API_URL+"announcements", {value:announcement});
    }

    static createTeams(eventId){
        return axios.post(API_URL+"events/"+eventId+"/create_teams");
    }

    static deletePostWritingData(id){
        return axios.delete(API_URL+"surveys/"+id);
    }

    static addPostWritingData(eventId, userId, word_count, total_time_written, start_time){
        return axios.post(API_URL+"surveys/"+eventId+"/"+userId, {word_count,total_time_written,start_time} )
    }
}

