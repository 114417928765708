import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import {ReactComponent as IconPencil} from '../../assets/icon-pencil.svg';
import {ReactComponent as IconArrow} from '../../assets/icon-arrow.svg';

import styles from './Challenge.module.scss';

import Request from "../../Request";
import {API_URL} from "../../config/config";

function Challenge(props){
	const challenge = props.challenge;

	const generateTeams = e => {
		setIsGenerating(true);
		if( window.confirm('Are you sure you want to create the teams for this challenge? You can only do this once.') ){
			setIsGenerating(true);
			Request.createTeams(challenge.id).then(response=>{
                alert('teams created');
                props.updateChallenges();
                setIsGenerating(false);
            });
		} else {
			setIsGenerating(false);
		}
	};

	const isEditable = isChallengeEditable(challenge);
	const dateFormat = isEditable ? 'dddd, MMMM Do' : 'MMMM D, YYYY'; //only show days of the week for current+future challenges

	const [isGenerating, setIsGenerating] = useState(false);

	const canGenerateTeams = () => {
		const now = new Date();
		const registrationClose = moment(challenge.registration_close_date + " 12:00").toDate();
		return (
			// getChallengeStatus(challenge) === 'upcoming'
			challenge.users.length !==0 && challenge.teams.length === 0
			&& registrationClose < now
		)
	};

	return (
		<div className={styles.challenge}>
			<header>
				<h1>{challenge.name}</h1>
				<p className={styles.timeline}>
					<label>
						<Moment format={dateFormat}>{challenge.start_date}</Moment>
					</label> – <label>
						<Moment format={dateFormat}>{challenge.end_date}</Moment>
					</label>
				</p>
			</header>
			<div className={styles.content}>
				<section className="data">
					<ul>
						{getChallengeStatus(challenge) !== 'upcoming' && <li><span>{challenge.total_words.toLocaleString()}</span> words written</li>}
						{getChallengeStatus(challenge) !== 'upcoming' && <li><span>{challenge.total_hours.toLocaleString()}</span> hours writing</li>}
						<li><span>{challenge.total_users.toLocaleString()}</span> users registered</li>
					</ul>
					{challenge.teams.length !== 0 && <a className="button big download" href={API_URL+"events/"+challenge.id+"/overall/"+localStorage.getItem("adminChallengeSessionKey")} target="_blank" download rel="noopener noreferrer"><IconArrow />Download Data</a>}
				</section>
				{isEditable === true && <form onSubmit={e=>e.preventDefault()}>
				</form>}
				<footer>
					{isEditable === true && <Link to={'/challenges/'+challenge.id} className={['button', styles.edit].join(' ')}><IconPencil/>Edit Details</Link>}
					{
						canGenerateTeams()
						&& <button disabled={isGenerating} className="button" onClick={generateTeams}>Generate Teams</button>
					}
					{challenge.teams.length !== 0 && <Link to={{pathname:'/challenges/'+challenge.id+'/teams',state:{challenge}}} className="button">View Teams</Link>}
					{challenge.users.length !==0 && <Link to={{pathname:'/challenges/'+challenge.id+'/participants',state:{challenge}}} className="button">View Participants</Link>}
				</footer>
			</div>
		</div>
	);
}

export default Challenge;

export function getChallengeStatus(challenge){
	let status;
	if( moment(challenge.start_date).isBefore(moment()) && moment(challenge.end_date).isAfter(moment()) ){
		status = 'active';
	} else if( moment(challenge.start_date).isAfter(moment()) ){
		status = 'upcoming';
	} else {
		status = 'past';
	}
	return status;
}

export function isChallengeEditable(challenge){
	return (getChallengeStatus(challenge) === 'upcoming');
}