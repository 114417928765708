import React, {useState, useEffect} from 'react';
import styles from './Announcement.module.scss';
import Request from "../../Request";

function Announcement(props){

	const [announcement, updateAnnouncement] = useState(props.announcement);
	const [newAnnouncement, updateNewAnnouncement] = useState('');

	useEffect(()=>{
        Request.getAnnouncement().then((response)=>{
            updateAnnouncement(response.data.value)
        })
	}, []);

	const clearAnnouncement = (e) => {
	    e.preventDefault();
		Request.updateAnnouncement("").then(response=>{
            updateNewAnnouncement(announcement);
            updateAnnouncement('');
        })
	};

	const saveAnnouncement = (e) => {
		e.preventDefault();
        Request.updateAnnouncement(newAnnouncement).then(response=>{
            updateAnnouncement(response.data.value);
            updateNewAnnouncement(response.data.value);
        })
	};

	return (
		<section className={styles.announcement}>
			<form onSubmit={saveAnnouncement}>
				{announcement && <>
					<label>Currently Active Site-Wide Announcement</label>
					<p>{announcement}</p>
					<button type="button" onClick={clearAnnouncement}>Clear Announcement</button>
				</>}
				{!announcement &&
				<>
					<label htmlFor="announcement">Create an announcement for active challenge(s)</label>
					<input type="text" id="announcement" className={styles.announcementField} value={newAnnouncement} onChange={e=>updateNewAnnouncement(e.target.value)} placeholder="Don’t forget to write a bunch…"/>
					<button disabled={newAnnouncement.length<1} type="button" onClick={saveAnnouncement}>Post Announcement</button>
				</>
				}
			</form>
		</section>
	);
}

export default Announcement;