import React, {useState, useEffect} from 'react';

import {useParams} from "react-router-dom";

import styles from './Participant.module.scss';
import PageHeader from "../Components/PageHeader/PageHeader";
import Request from "../Request";
import ChallengeProgress from "../Components/ChallengeProgress/ChallengeProgress";
import AchievementsList from "../Components/AchievementsList/AchievementsList";
import Moment from "react-moment";
import PostWritingData from "../Components/PostWritingData/PostWritingData";

function Participant(props){

	let { id } = useParams();
	console.log(id);
	const [history, setHistory] = useState(null);

	useEffect(()=>{
		Request.getHistoryForUser(id).then((response)=>{
			console.log(response.data);
			setHistory(response.data);
		})
	},[]);

	if(history==null){
	    return null;
    }

	const dateFormat = 'MMMM D, YYYY'; //only show days of the week for current+future challenges

	return (
		<>
			<PageHeader pageTitle={history.user_info.first_name+" "+history.user_info.last_name} showNewChallengeButton={false} showBackButton={true}/>
			<main className={styles.participantHistory}>
				{history.events.length === 0 && <h2>This user has not participated in any events yet.</h2>}
				<ul className="sectioned-list">
					{history.events.map(challenge=>
					<li key={challenge.event_id}>
						<header>
							<h1>{challenge.event_name} <small><Moment format={dateFormat} date={challenge.start_date} /> – <Moment format={dateFormat} date={challenge.end_date} /></small></h1>
							<h2>Team: {challenge.team_name}</h2>
						</header>
						<section className="data row">
							<ul>
								<li className="red"><span>{parseInt(challenge.total_words,10).toLocaleString()}</span> total words written</li>
								<li className="red"><span>{((challenge.total_time_in_seconds/60/60).toFixed(2) +"").toLocaleString()}</span> total hours writing</li>
							</ul>
						</section>
						<section className="data small row">
							<ul>
								<li>Most Words: <span>{challenge.most_words_formatted_date} | {challenge.most_words.toLocaleString()} words</span></li>
								<li>Most Hours: <span>{challenge.most_time_formatted_date} | {(challenge.most_time_in_seconds/60/60).toFixed(2).toLocaleString()} hours</span></li>
							</ul>
						</section>
						<div className="two-column">
							<section className="challengeProgress">
								<ChallengeProgress progress={challenge.user_results.daily_submission_results} />
							</section>
							<section className="achievements">
								<AchievementsList />
							</section>
						</div>
                        <PostWritingData userId={history.user_info.id} firstName={history.user_info.first_name} challenge={challenge} eventId={challenge.event_id} postWritingData={history.work.filter(w=>w.event_id == challenge.event_id)}/>
					</li>
					)}
				</ul>
			</main>
		</>
	);
}

export default Participant;