import React, {useState, useEffect,useCallback} from 'react';

import styles from './Dashboard.module.scss';
import Challenge, {getChallengeStatus} from "../Components/Challenge/Challenge";
import Request from "../Request"
import PageHeader from "../Components/PageHeader/PageHeader";
import Announcement from "../Components/Announcement/Announcement";

function Dashboard(){
	//TODO load the challenges
	/*TODO sort the challenges by date according to each section
		* active challenges - the one that ends first is listed first
		* upcoming challenges - the one that starts the soonest is listed first
		* past challenges - the most recent to be completed is listed first
 	*/

	const [challenges, setChallenges] = useState([]);

    const updateChallenges = ()=>{
        Request.getAllEvents().then((response)=>{
            setChallenges(response.data.events);
        })
    }

	useEffect(()=>{
        updateChallenges();
    },[]);

	function hasChallengesWithStatus(status){
		if( !challenges ){
			return false;
		}
		return challenges.some(challenge => {
			return getChallengeStatus(challenge) === status;
		});

	}

	return (
		<>
			<PageHeader pageTitle="Manage Challenges" showNewChallengeButton={true}/>
			<Announcement />
			<main className={styles.dashboard}>
				<section className={styles.challenges}>
					{ ( hasChallengesWithStatus('active') || hasChallengesWithStatus('upcoming') ) &&
						<div className={styles.currentChallenges}>
							{ hasChallengesWithStatus('upcoming') &&
								<section className={styles.upcomingChallenges}>
									<label htmlFor="upcoming-challenges">Upcoming Challenges</label>
									<ul>
										{challenges.filter(challenge => getChallengeStatus(challenge) === 'upcoming').map(challenge => {
											return <li key={challenge.id} className={styles.challenge}>
												<Challenge updateChallenges={updateChallenges} challenge={challenge}/></li>
										})}
									</ul>
								</section>
							}
							{hasChallengesWithStatus('active') &&
							<section className={styles.activeChallenges}>
								<label htmlFor="active-challenges">Active Challenges</label>
								<ul id="active-challenges">
									{challenges.filter(challenge => getChallengeStatus(challenge) === 'active').map(challenge => {
										return <li key={challenge.id} className={styles.challenge}>
											<Challenge updateChallenges={updateChallenges} challenge={challenge}/></li>
									})}
								</ul>
							</section>
							}
						</div>
					}
					{ hasChallengesWithStatus('past') &&
						<section className={styles.pastChallenges}>
							<label htmlFor="past-challenges">Past Challenges</label>
							<ul>
								{challenges.filter(challenge => getChallengeStatus(challenge) === 'past').map((challenge, index) => {
									return <li key={challenge.id} className={styles.challenge}>
										<Challenge updateChallenges={updateChallenges} challenge={challenge}/>
									</li>
								})}
							</ul>
						</section>
					}
				</section>
			</main>
		</>
	);
}

export default Dashboard;