import React, {useState, useEffect} from 'react';

import styles from './People.module.scss';
import PageHeader from "../Components/PageHeader/PageHeader";
import UserListing from "../Components/UserListing";
import Request from "../Request";

function People(){

	const [users, setUsers] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [newAdminEmail, updateNewAdminEmail] = useState('');
	const [newAdminFirst, updateNewAdminFirst] = useState('');
	const [newAdminLast, updateNewAdminLast] = useState('');

	useEffect(()=>{
		Request.getAllUsers().then((response)=>{
			setUsers(response.data.users);
		});

		Request.getAllAdmins().then((response)=>{
			setAdmins(response.data.admins);
		});
	},[]);

	const addAdmin = (e) => {
		e.preventDefault();
		const newAdmin = {
			email:newAdminEmail,
			first_name:newAdminFirst,
			last_name:newAdminLast
		};
		Request.createAdmin(newAdmin).then( (response) => {
			setAdmins([...admins, response.data]);
			updateNewAdminEmail('');
			updateNewAdminFirst('');
			updateNewAdminLast('');
		});
	};

	const removeAdmin = (admin) => {
		if( window.confirm('Are you sure you want to remove this user’s admin privileges?') ){
			Request.removeAdmin(admin).then((response) =>{
				setAdmins(admins.filter(a=>a !== admin));
			});
		}
	};

	return (
		<>
			<PageHeader pageTitle="Participant Network" />
			<main className={styles.people}>
				<section className={styles.admins}>
					<h2>Admin Users</h2>
					<div className="two-column">
						<ul className="user-list single">
							{admins.map(admin => <li key={admin.email}>
								<h3>{admin.email}</h3>
								<button className="button" onClick={e=>{removeAdmin(admin)}}>Remove Admin Privileges</button>
							</li>)}
						</ul>
						<form onSubmit={addAdmin}>
							<h3>Add Admin User</h3>
							<label htmlFor="new-admin-email">BSU Email</label>
							<input id="new-admin-email" required type="email" placeholder="charlie@bsu.edu" value={newAdminEmail} onChange={e=>updateNewAdminEmail(e.target.value)}/>

							<label htmlFor="new-admin-first">First Name</label>
							<input id="new-admin-first" required type="text" placeholder="Charlie" value={newAdminFirst} onChange={e=>updateNewAdminFirst(e.target.value)}/>

							<label htmlFor="new-admin-last">Last Name</label>
							<input id="new-admin-last" required type="text" placeholder="Cardinal" value={newAdminLast} onChange={e=>updateNewAdminLast(e.target.value)}/>

							<button className="button big" type="submit" disabled={newAdminEmail.length < 9}>+ Add Admin</button>
						</form>
					</div>
				</section>
				<section className={styles.participants}>
					<h2>Current and Past Participants</h2>
					<ul className="user-list">
						{users.map(user=>
							<li>
								<UserListing user={user}/>
							</li>
						)}
					</ul>
				</section>
			</main>
		</>
	);
}

export default People;