import React from 'react';
import {useHistory} from "react-router-dom";

import styles from './Teams.module.scss';
import PageHeader from "../Components/PageHeader/PageHeader";
import UserListing from "../Components/UserListing";
import AchievementsList from "../Components/AchievementsList/AchievementsList";

function Teams(){
    let history = useHistory();
    let challenge = history.location.state.challenge;
    console.log(challenge);
	return (
		<>
			<PageHeader pageTitle={"Teams for “" + challenge.name + '”'} showBackButton={true}/>

			<main className={styles.teams}>
				<section className="data">
					<h1>Overall Challenge Stats</h1>
					<ul>
						<li><span>{challenge.total_words.toLocaleString()}</span> words written</li>
						<li><span>{challenge.total_hours.toLocaleString()}</span> hours writing</li>
						<li><span>{challenge.total_users.toLocaleString()}</span> users registered</li>
						<li><span>{challenge.teams.length.toLocaleString()}</span> total teams</li>
					</ul>
				</section>
				<ul className={styles.teamsList}>
				{
					challenge.teams.map(team=>{
						return(
							<li>
								<h2>{team.name}</h2>
								<div className="two-column">
									<section className={"data " + styles.dataContainer}>
										<ul>
											<li><span>{team.total_words.toLocaleString()}</span> words written</li>
											<li><span>{team.total_hours.toLocaleString()}</span> hours writing</li>
										</ul>
										<div>
											<AchievementsList achievements={[]}/>
										</div>
									</section>

									<ul className={"user-list " + styles.teamMembers}>
										{team.users.map((user)=>
											<li>
												<UserListing challenge={challenge} user={user}/>
											</li>
										)}
									</ul>
								</div>
							</li>
						)
					})
				}
				</ul>
			</main>
		</>
	);
}

export default Teams;