import React, {useState, useEffect,useCallback} from 'react';
import {Link} from "react-router-dom";

import styles from './Error.module.scss';
import {ReactComponent as IconBack} from '../assets/icon-back.svg';

function Dashboard(){
	return (
		<>
			<main className={styles.error}>
				<h1>Don’t put down that <s>quill</s> <s>typewriter</s> laptop!</h1>
				<h2>The site encountered an error.</h2>
				<p>If you keep having this problem, get in touch with <a href="mailto:digitalcorps@bsu.edu">The Digital Corps (digitalcorps@bsu.edu)</a>.</p>
				<Link className={styles.back} to={'/'}><IconBack/> Return Home</Link>
			</main>
		</>
	);
}

export default Dashboard;