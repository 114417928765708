import React from 'react';
import {Link} from "react-router-dom";
import ChallengeProgress from "./ChallengeProgress/ChallengeProgress";

export default function UserListing(props){
	const user = props.user;
	return (
		<>
			<div>
				<h3>{user.first_name} {user.last_name}</h3>
				<a href={"mailto:"+user.email} target="_blank" rel="noopener noreferrer">{user.email}</a>
				<h4>{user.department}</h4>
                {user.user_results &&
                    <ChallengeProgress progress={user.user_results.daily_submission_results}/>
                }

			</div>
			<Link className="button" to={{pathname:'/participants/'+user.id, state:{challenge:props.challenge}}}>View Details</Link>
		</>
	);
}