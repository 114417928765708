import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Dashboard from './Containers/Dashboard';
import Challenge from './Containers/Challenge';
import Teams from './Containers/Teams';
import Participants from './Containers/Participants';
import Participant from './Containers/Participant';
import Error from './Containers/Error';

import './global.scss';

import "./config/config";
import {BASE_URL, SHIBBOLETH_URL} from "./config/config";
import People from "./Containers/People";

//taken from https://davidwalsh.name/query-string-javascript
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function App() {
    //if there is session information in the url
    let sessionKey = getUrlParameter("adminChallengeSessionKey")
    if(sessionKey!==null && sessionKey!==""){
        localStorage.setItem("adminChallengeSessionKey", sessionKey)
        window.location = BASE_URL;
        return;
    }
    //if there is no session lets go get one
    if(localStorage.getItem("adminChallengeSessionKey")==="" || localStorage.getItem("adminChallengeSessionKey")===null){
        window.location = SHIBBOLETH_URL;
        return;
    }


	return (
		<div className="App">
			<Router basename={process.env.PUBLIC_URL}>
				<Switch>
					<Route exact path="/"><Dashboard/></Route>
					<Route exact path="/people"><People/></Route>
					<Route exact path="/challenges/new"><Challenge/></Route>
					<Route exact path="/challenges/:id"><Challenge/></Route>
					<Route exact path="/challenges/:id/teams"><Teams/></Route>
					<Route exact path="/challenges/:id/participants"><Participants/></Route>
					<Route exact path="/participants/:id"><Participant/></Route>
					<Route exact path="/error"><Error/></Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
