import React, {useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';

import styles from './Participants.module.scss';
import PageHeader from "../Components/PageHeader/PageHeader";
import UserListing from "../Components/UserListing";

function Participants(){
    let history = useHistory();
    let textarea = useRef(null);
    if(history.location.state == null || history.location.state.challenge==null){
        history.push("/");
        return null;
    }

    const copyEmails = (event)=>{
        textarea.current.select();
        document.execCommand('copy');
        if (window.getSelection) { // All browsers, except IE <=8
            window.getSelection().removeAllRanges();
        } else if (document.selection) { // IE <=8
            document.selection.empty();
        }
    }

    let challenge = history.location.state.challenge;
    let userEmails = challenge.users.map(user => user.email);
	return (
		<>
			<PageHeader pageTitle={"Participants in “" + challenge.name + '”'} showBackButton={true}/>
			<main className={styles.participants}>
				<div className={styles.emailListContainer}>
					<h3>Email List:</h3>
					<textarea ref={textarea} className={styles.emailList} contentEditable={false} value={userEmails.join('; ')} onFocus={e=>e.target.select()}></textarea>
					<button className={styles.copyEmails} onClick={copyEmails}>copy emails</button>
				</div>
				<h2>Currently Registered Users</h2>
				<ul className="user-list">
					{
						challenge.users.map((user)=>
							<li key={user.id}>
								<UserListing user={user}/>
							</li>)
					}
				</ul>
			</main>
		</>
	);
}

export default Participants;