import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";

import styles from './Challenge.module.scss';
import PageHeader from "../Components/PageHeader/PageHeader";
import Request from "../Request";

function Challenge() {

    const {id} = useParams();
    const history = useHistory();
    const [challenge, updateChallenge] = useState(null);


    useEffect(() => {
        if (id) {
            Request.getEvent(id).then((response) => {
                updateChallenge(response.data)
            })
        } else {
            updateChallenge({});
        }
    }, [id]);


    const newOrExistingHeading = (id) ? 'Edit' : 'Create';
    const newOrExistingButtonLabel = (id) ? 'Update' : 'Create';

    const handleChallengeSubmit = e => {
        e.preventDefault();
        if (!challengeDatesAreValid()) {
            return;
        }
        if (id) {
            Request.updateEvent(challenge).then(response => {
                updateChallenge(response.data);
                alert("Challenge Updated")
            })
        } else {
            Request.createEvent(challenge).then(response => {
                updateChallenge(response.data);
                alert("Challenge Created");
                history.push("/")

            })
        }
    };

    const challengeDatesAreValid = () => {

        const now = new Date();
        const start = new Date(challenge.start_date + " 12:00");
        const end = new Date(challenge.end_date + " 12:00");
        const registrationOpen = new Date(challenge.registration_open_date + " 12:00");
        const registrationClose = new Date(challenge.registration_close_date + " 12:00");

        if (start.getTime() <= now.getTime()) {
            alert('Start date must be after today');
            return false;
        }
        if (start.getTime() > end.getTime()) {
            alert('Start date must be before end date');
            return false;
        }
        if (start.getDay() !== 1) {
            alert('Start date must be a Monday');
            return false;
        }
        if (end.getDay() !== 0) {
            alert('End date must be a Sunday');
            return false;
        }
        if (registrationOpen.getTime() > registrationClose.getTime()) {
            alert("Registration open date must be before registration close date");
            return false;
        }
        if (registrationClose.getTime() > start.getTime()) {
            alert("Registration close date must be before the challenge start date");
            return false;
        }
        return true;
    };

    return (
        challenge &&
        <>
            <PageHeader pageTitle={newOrExistingHeading + ' Challenge'}/>

            <main className={styles.challenge}>
                <form onSubmit={handleChallengeSubmit}>
                    <fieldset>
                        <h2>Challenge Overview</h2>
                        <p>Think of an enticing name to help recruit participants.</p>
                        <label htmlFor="name">Challenge Name</label>
                        <input required
                               placeholder="Spring 2020 Challenge – or – Spring “Break”away ‘20! – or – Challenge #123"
                               type="text" id="name"
                               onChange={e => updateChallenge({...challenge, name: e.target.value})}
                               value={challenge.name || ''}/>
                    </fieldset>

                    <fieldset>
                        <h2>Challenge Dates</h2>
                        <p>Challenges should be 14 days in total, and should span from a Monday until a second
                            Sunday.</p>

                        <div className="two-column">
                            <div>
                                <label htmlFor="start-date">Start Date</label>
                                <input required type="date" id="start-date"
                                       onChange={e => updateChallenge({...challenge, start_date: e.target.value})}
                                       value={challenge.start_date || ''}/>
                            </div>

                            <div>
                                <label htmlFor="end-date">End Date</label>
                                <input required type="date" id="end-date"
                                       onChange={e => updateChallenge({...challenge, end_date: e.target.value})}
                                       value={challenge.end_date || ''}/>
                            </div>
                        </div>
                    </fieldset>

                    {
                        (!challenge.teams || challenge.teams.length === 0) &&
                        <fieldset>
                            <h2>Registration</h2>
                            <p>Registration should close before the start date. After registration closes the users should be reviewed and the teams should be generated by an admin.</p>
                            <div className="two-column">
                                <div>
                                    <label htmlFor="registration-open-date">Registration Open Date</label>
                                    <input required type="date" id="registration-open-date"
                                           onChange={e => updateChallenge({
                                               ...challenge,
                                               registration_open_date: e.target.value
                                           })}
                                           value={challenge.registration_open_date || ''}/>
                                </div>

                                <div>
                                    <label htmlFor="registration-close-date">Registration Close Date</label>
                                    <input required type="date" id="registration-close-date"
                                           onChange={e => updateChallenge({
                                               ...challenge,
                                               registration_close_date: e.target.value
                                           })}
                                           value={challenge.registration_close_date || ''}/>
                                </div>
                            </div>
                        </fieldset>
                    }


                    <fieldset>
                        <h2>Surveys</h2>
                        {/*TODO add in the link to the survey instructions document*/}
                        <p>To create and set up surveys for each challenge, follow <a href="https://ballstate.box.com/s/5ykfw8ivwp6ce53d2lkfdfxqpdwniurr" target="_blank"
                                                                                      rel="noreferrer noopener">these
                            instructions</a>.</p>
                        <label htmlFor="writing-survey-url">Survey URL</label>
                        <input required placeholder="https://drive.google.com…" type="url" id="writing-survey-url"
                               onChange={e => updateChallenge({...challenge, survey_url: e.target.value})}
                               value={challenge.survey_url || ''}/>

                        <label htmlFor="writing-survey-csv-url">Survey CSV URL</label>
                        <input required placeholder="https://drive.google.com…" type="url" id="writing-survey-csv-url"
                               onChange={e => updateChallenge({...challenge, survey_csv_url: e.target.value})}
                               value={challenge.survey_csv_url || ''}/>
                    </fieldset>

                    <footer>
                        <button className="button big centered" type="submit">{newOrExistingButtonLabel} Challenge
                        </button>
                        <Link to={'/'}>Cancel</Link>
                    </footer>

                </form>
            </main>
        </>
    );
}

export default Challenge;