import React from 'react';
import styles from './PageHeader.module.scss';
import {Link, NavLink, useHistory} from "react-router-dom";

import logo from '../../assets/bsu-logo.svg';
import {ReactComponent as IconBack} from '../../assets/icon-back.svg';

function PageHeader(props){
	const history = useHistory();
	return (
		<header className={styles.pageHeader}>
			<div>
				<div>
					<Link to={'/'} className={styles.logo}><img src={logo} alt="Ball State University"/><span>Faculty Writing Challenge</span></Link>
				</div>
				<h1 className="page-title">{props.showBackButton && <span className={styles.backArrow} onClick={e=>history.goBack()}><IconBack /></span>}{props.pageTitle}</h1>
			</div>
			{props.showNewChallengeButton && <Link to={'/challenges/new'} className="button big">+ New Challenge</Link>}
			<ul className={styles.sectionMenu}>
				<li><NavLink exact to="/" activeClassName={styles.active}>Challenges</NavLink></li>
				<li><NavLink exact to="/people" activeClassName={styles.active}>People</NavLink></li>
			</ul>
		</header>
	);
}

export default PageHeader;