import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom"
import Request from "../../Request";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment"

import "react-datepicker/dist/react-datepicker.css";
import styles from './PostWritingData.module.scss';
import Moment from "react-moment";


const PostWritingData = ({postWritingData, eventId, userId, challenge, firstName}) => {
    let [isOpen, setIsOpen] = useState(false);
    let [submissions, setSubmissions] = useState([]);
    let [newDate, setNewDate] = useState(new Date());
    let [timeWrittenInMinutes, setTimeWrittenInMinutes] = useState(0);
    let [wordCount, setWordCount] = useState(0);

    useEffect(() => {
        setSubmissions(postWritingData);
    }, []);

    const removeSubmission = (event, submissionId) => {
        if (!window.confirm("are you sure you want to remove this submission?")) {
            return;
        }

        Request.deletePostWritingData(submissionId).then(() => {
            setSubmissions(submissions.filter(s => s.survey_id != submissionId));
        })
    };

    const addPostWritingSurveyData = (e) => {
        e.preventDefault();
        if (wordCount == 0 || timeWrittenInMinutes == 0 || newDate == null) {
            alert("you must set all the values to add writing data");
            return;
        }
        let formattedDate = moment(newDate).format("Y-M-D");
        let formattedTimeWritten = parseInt(timeWrittenInMinutes / 60) + ":" + timeWrittenInMinutes % 60 + ":00";
        Request.addPostWritingData(eventId, userId, wordCount, formattedTimeWritten, formattedDate).then(response => {
            let temp = Array.from(submissions);
            temp.push(response.data);
            console.log(temp);
            setSubmissions(temp);
            setTimeWrittenInMinutes(0);
            setWordCount(0);
            setNewDate(new Date());
        });
    };


    return (
        <section className={styles.postWritingData}>
            {
                <button onClick={() => {
                    setIsOpen(!isOpen)
                }}>{isOpen ? "close data editor" : "modify writing data"}</button>
            }
            {isOpen &&
            <>
                <h2>Edit {firstName}’s writing data for the {challenge.event_name} Writing Challenge</h2>
                <div className={styles.columns}>
                    <div>
                        <table className={styles.postWritingTable}>
                            <thead>
                            <tr>
                                <th>Submitted Time</th>
                                <th>Time written</th>
                                <th>Word Count</th>
                                <th>Delete</th>
                            </tr>

                            </thead>
                            <tbody>
                            {submissions.map(submission =>
                                <tr key={submission.survey_id}>
                                    <td>
                                        <Moment format={'dddd, MMMM Do @ H:mm a'}>{submission.submitted_time}</Moment>
                                    </td>
                                    <td>{submission.total_time_written}</td>
                                    <td>{submission.word_count}</td>
                                    <td>
                                        <button onClick={e => removeSubmission(e, submission.survey_id)}>Delete</button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <form onSubmit={addPostWritingSurveyData}>
                        <h3>Add Writing Data</h3>
                        <div>
                            <label>Date:</label>
                            <DateTimePicker disableClock={true} required value={newDate} onChange={(date) => {
                                setNewDate(date)
                            }}/>
                        </div>
                        <div className={styles.columns}>
                            <div className={styles.columnWrapper}>
                                <label>Time written in minutes:</label>
                                <input placeholder="30" type="number" min="1" required value={timeWrittenInMinutes}
                                       onChange={e => setTimeWrittenInMinutes(e.target.value)}/>
                            </div>
                            <div className={styles.columnWrapper}>
                                <label>Word Count: </label>
                                <input placeholder="200" type="number" min="1" required value={wordCount}
                                       onChange={e => setWordCount(e.target.value)}/>
                            </div>
                        </div>
                        <input type="submit"/>
                    </form>
                </div>
            </>
            }
        </section>
    )
};

export default PostWritingData;