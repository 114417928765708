import React from 'react';

import styles from './AchievementsList.module.scss';

function AchievementsList(props) {
	return null;
	return (
		<div className={styles.achievementsList}>
			<ul>
				{/*TODO map achievements from props*/}
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
				<li>Sample achievement text here</li>
			</ul>
		</div>
	);
}

export default AchievementsList;